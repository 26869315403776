const courses = [
  {
    "id": 1,
    "title": "SUEÑO LÚCIDO TOTAL: El arte del sueño consciente y la experiencia OBE en 30 días",
    
    
    
  },
  {
    id: 2,
    title: "Curso de Meditación Avanzado",    
  },
  {
    id: 3,
    title: 'Transforma tu Vida con la Meditación Guiada por Nube María',
    
    
  },
  {
    id: 4,
    title: '7 Días Transformando mi Vida con los Arcángeles: Despierta tu Poder Espiritua',
   
  },
  {
    id: 5,
    title: 'Taller de manifestación con la Ley de Atracción',
    
  }
];

export default courses;
